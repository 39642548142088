import React, { useState, useRef, useEffect } from 'react';
import styled, { createGlobalStyle, keyframes  } from 'styled-components';
import { FaArrowCircleDown , FaBars, FaLock, FaUnlock, FaCoins, FaShieldAlt, FaNetworkWired  } from 'react-icons/fa';
import logo from './favicon.png'; 
import ContactusImage from './contactus.png';
import { IoMdClose } from "react-icons/io";
import MainImage from './mainscreenimage.png';
import caught from './caught.png';
import AboutUsImage from './aboutus.png';
import { MdEmail } from "react-icons/md";
import { TextField, Button, Box, Typography, Alert } from '@mui/material';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';


const dummyPasswd = `
root:x:0:0:root:/root:/bin/bash
daemon:x:1:1:daemon:/usr/sbin:/usr/sbin/nologin
bin:x:2:2:bin:/bin:/usr/sbin/nologin
sys:x:3:3:sys:/dev:/usr/sbin/nologin
sync:x:4:65534:sync:/bin:/bin/sync
`;

const DummyShadow = `root:*:18648:0:99999:7:::
daemon:*:18648:0:99999:7:::
bin:*:18648:0:99999:7:::
sys:*:18648:0:99999:7:::
sync:*:18648:0:99999:7:::`;


const GlobalStyle = createGlobalStyle`
  body {
    background-color: #f5f5f5 !important;
    margin: 0;
    font-family: 'Inter', sans-serif;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #273c75;
  color: white;
  text-align: center;
`;

const LoadingIcon = styled.div`
  font-size: 5rem;
  margin-bottom: 1rem;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingBar = styled.div`
  border: 5px solid #f3f3f3;
  border-top: 5px solid #273c75;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
  margin-bottom: 1rem;
`;


const Navbar = styled.nav`
  background-color: #000D4E;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: white;
  font-family: 'Inter', sans-serif;
  position: relative;
  @media (max-width: 768px) {
justify-content: start;
  }

`;



const Logo = styled.img`
  border-radius: 50%;
  height: 50px;
  margin-right: 10px;
`;

const CompanyName = styled.span`
  font-size: 24px;
  color: white;
  font-weight:bold;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
color:white;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled.a`
  margin-left: 20px;
  color:white;
  text-decoration: none;

  font-size: 16px;
  text-transform: uppercase;
`;

const BurgerMenu = styled( FaBars)`
  display: none;
  cursor: pointer;
  font-size: 25px;
  @media (max-width: 768px) {
    display: block;
  }
`;

const CloseMenu = styled( IoMdClose)`
  display: none;
  cursor: pointer;
  font-size: 20px;
  @media (max-width: 768px) {
    display: block;
  }
`;

const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000D4E;
  position: absolute;
  top: 60px;
  left: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  width: 100%;
  height: 100vh; /* Set the height to full screen */
  padding: 20px 0;
  transition: left 0.3s ease-in-out;
  z-index: 1;

  @media (min-width: 769px) {
    display: none;
  }
`;

const MobileNavLink = styled.a`
  margin: 10px 0;
  color: white;
  text-decoration: none;
  font-size: 16px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid white;
`;
const MainSection = styled.section`
 
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  flex: 0.5
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    padding-right: 0;
  }
`;

const MainImageImg = styled.img`
width:450px;
 @media (max-width: 768px) {
   max-width: 200px; 
  }
 
`;

const TextContainer = styled.div`
  text-align: right;
  color: black;
  max-width: 500px; /* Increased max-width */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 10%;
  margin-top: 0;
 @media (min-width: 768px) {
   max-width: 700px; 
  }
  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    padding-right: 0;
    margin-top: 0;
  }
`;

const Heading = styled.h1`
  margin: 0;
  font-size: 48px;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const SubText = styled.p`
  font-size: 18px;
  margin: 10px 0;
  line-height: 1.4;
  color: black;
  word-break: break-word;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ButtonE = styled.button`
  background-color: #000D4E;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #13278a;
  }

  svg {
    margin-left: 10px;
  }
`;

const FeaturesSection = styled.section`
 height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
  height: 100%;
    padding: 40px 20px;
  }
`;

const FeaturesHeading = styled.h2`
  font-size: 36px;
  margin-bottom: 20px;
  margin-top: 40px;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const FeaturesSubText = styled.p`
  font-size: 18px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;

  @media (max-width: 768px) {
  display: flex;
  flex-direction: column;
  align-items:center;
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

const FeatureCard = styled.div`
  background-color: #000D4E;
  border-radius: 50%;  // Make the card a circle
  width: 250px;        // Set a fixed width
  height: 250px;       // Set a fixed height equal to width
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  // Center the content
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  @media (max-width: 768px) {
 
   
  }
`;


const FeatureIcon = styled.div`
  font-size: 40px;
  margin-bottom: 20px;
`;

const FeatureText = styled.p`
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
  color: white;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const JoinWaitlistSection = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px 20px;
  background: #f5f5f5;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 40px 20px;
    height: 100%;
  }
`;

const JoinWaitlistHeading = styled.h2`
  font-size: 36px;
  margin-bottom: 10px;
  color: #000;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const JoinWaitlistSubheading = styled.p`
  font-size: 18px;
  margin-bottom: 30px;
  color: #000;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const JoinWaitlistForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  max-width: 100%;
  padding: 20px;
  @media screen and (max-width: 768px) {
                width: 100%;
                
              }
`;

const OtpField = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 30%;
  margin-bottom: 20px;

  & > div {
    flex: 1;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

    @media screen and (max-width: 768px) {
                width: 100%;
                
              }
`;


const PricingSection = styled.section`
   height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #f5f6fa;

  @media (max-width: 768px) {
  height: 100%;
    padding: 40px 20px;
  }
`;

const PricingHeading = styled.h2`
  font-size: 36px;
  margin-bottom: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const PricingSubText = styled.p`
  font-size: 18px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

const PricingCard = styled.div`
  background-color: #ffffff;
  border: 2px solid #000D4E;
  border-radius: 8px;
 
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const PricingAmount = styled.h3`
  font-size: 24px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const PricingDescription = styled.p`
  font-size: 16px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const CreditsIcon = styled(FaCoins)`
  font-size: 40px;
  margin-bottom: 10px;
  color: #ffd700;
`;

const PricingButton = styled.button`
  background-color: #000D4E;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #13278a;
  }
`;

const AboutUsSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px 20px;
  

  height: 100vh; /* Set the height to full screen */
  box-sizing: border-box;
  color: #000; /* Darker text for better readability */
  
  @media (max-width: 768px) {
    padding: 40px 20px;
    height: 100%;
  }
`;

const AboutUsMainContainer = styled.div`
  display:flex;
  flex-direction: row;
  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    padding-right: 0;
    flex-direction:column;
  }
`;

const AboutUsImageContainer = styled.div`
  display:flex;
align-items: center;
padding-left: 20px;
  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    padding-right: 0;
    padding-left: 0;
    flex-direction:column;
  }
`;

const AboutUsContainer = styled.div`
  display:flex;
align-items: center;
  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    padding-right: 0;
    flex-direction:column;
  }
`;

const AboutUsImageComp = styled.img`
width:400px;
  @media (max-width: 768px) {
    width: 200px;
  }
`;

const AboutUsHeading = styled.h2`
  font-size: 36px;
  margin-bottom: 20px;
  margin-top: 40px;
  color: #000; /* Matching the theme color */
  /* Uppercase for a modern look */

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const AboutUsText = styled.p`
  font-size: 18px;
  margin-bottom: 40px;
  max-width: 800px;
  line-height: 1.6; /* Increased line height for readability */
  color: #000; /* Slightly lighter text color */
text-align: justify;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ContactUsSection = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px 20px;
  background: #f5f5f5;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 40px 20px;
    height: 100%;
  }
`;

const ContactUsHeading = styled.h2`
  font-size: 36px;
  margin-bottom: 10px;
  color: #000;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const ContactUsSubheading = styled.p`
  font-size: 18px;
  margin-bottom: 30px;
  color: #000;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ContactUsMDiv = styled.div`
width: 100%;
display:flex;
flex-direction: row;
 @media (max-width: 768px) {
    flex-direction: column;
    
  }
`;

const ContactUsImgContainer = styled.div`
display:flex;
flex-direction: column;
flex: 0.5;
align-items: center;
justify-items:center;

`;

const ContactUsImg = styled.img`
 width: 350px;
  @media (max-width: 768px) {
    width: 200px;
  }
`;

const ContactForm = styled.form`
flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: center;
 
  max-width: 500px;
  padding: 20px;
  
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 20px;
padding-right:20px;
padding-left:20px;
  & > div {
    flex: 1;
  }

  & > div:not(:last-child) {
    margin-right: 10px;
  }

    @media (max-width: 768px) {
   flex-direction: column;
   padding-top: 10px;
   justify-content: center;
   padding:0;
    & > div:not(:last-child) {
    margin-right: 0px;
  }
  }
`;

const Label = styled.label`
display: flex;
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;

  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding-top: 10px;
   padding-bottom: 10px;
  font-size: 16px;
  border: 1px solid #000;
  border-radius:2px;
  font-family: 'Inter', sans-serif;
 box-shadow: 0 0 3pt 1pt #D3D3D3;
  &:focus {
    border-color: #000;
    outline: none;
  }
`;

// const TextArea = styled.textarea`
//   width: 100%;
//   font-family: 'Inter', sans-serif;
//    padding-bottom: 20px;
//   font-size: 16px;
//   border: 1px solid #000;
//   border-radius: 2px;
//   margin-bottom: 20px;
//   box-shadow: 0 0 3pt 1pt #D3D3D3;
// resize: none;
//   &:focus {
//     border-color: #000;
//     outline: none;
//   }
// `;

const SubmitButton = styled.button`
  background-color: #2332B7;
  color: white;
  font-size: 15px;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
width: calc(100% - 100px);
box-shadow: 0 0 5pt 2pt #D3D3D3;
  &:hover {
    background-color: #0088cc;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 10px 16px;
  }
`;



const Footer = styled.footer`
  background-color: #000D4E;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
    align-items: center;
  }
`;

const FooterLogo = styled.img`
  height: 40px;
  margin-bottom: 10px;
`;

const FooterNav = styled.nav`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

const FooterNavLink = styled.a`
  color: white;
  text-decoration: none;
  margin: 5px 0;

  &:hover {
    text-decoration: underline;
  }
`;

const Copyright = styled.p`
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
  width: 100%;
`;

const FooterDiv = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between; 
width: 100%;

 @media (max-width: 768px) {
 flex-direction: column !important; 
 }
`;
const App = () => {
   const [loading, setLoading] = useState(true);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [unlocked, setUnlocked] = useState(false);
const solutionsRef = useRef(null);
const [progress, setProgress] = useState(false);

const [email, setEmail] = useState('');
const [emailError, setEmailError] = useState(false);
const [otp, setOtp] = useState(['', '', '', '', '','']);
const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
const [otpError, setOtpError] = useState(false);
const [isOtpVerified, setIsOtpVerified] = useState(false);

const [name, setName] = useState('');
const [nameError, setNameError] = useState(false);

const [content, setContent] = useState(0);

useEffect(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const fileParam = urlParams.get('file');

  if (fileParam) {
    setLoading(false);
    if (fileParam === '../../etc/passwd') {
      setContent(1);
    }
    else if (fileParam === '../../etc/shadow') {
      setContent(2);
    }  
    else {
      setContent(3);
      document.body.style = 'background-color: #f5f5f5;';
    }
  }
}, []);

const handleEmailChange = (e) => {
  setEmail(e.target.value);
  setEmailError(false);
};


const handleNameChange = (e) => {
  setName(e.target.value);
  setNameError(false);
};


const handleEmailSubmit = async () => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 

  if (name === "") {
    setNameError(true);
    return; 
  }

  if (!emailRegex.test(email)) {
    setEmailError(true);
    return; 
  }

  try {
    setProgress(true);
    const response = await axios.post("https://api.hash-secure.com/v1/join-waitlist", { name, email });
    
    if (response.status === 200) {
      if (response.data.message === 'Email already verified and on the waitlist') {
        setProgress(false);
        alert('You are already on the waitlist.');
        return;
      } else {
        setProgress(false);
        setIsEmailSubmitted(true);
      }
    }
  } catch (error) {
    console.error("Error submitting email:", error);

  }
};


const handleOtpChange = (index, value) => {
  const newOtp = [...otp];
  newOtp[index] = value;
  setOtp(newOtp);
  setOtpError(false);
};

const handleOtpSubmit = async () => {
  const otpString = otp.join(''); 

  try {
    const response = await axios.post("https://api.hash-secure.com/v1/verify-otp", { email, otp: otpString });
    
    if (response.status === 200) {
      setIsOtpVerified(true);
    } else {
      setOtpError(true);
    }
  } catch (error) {
    console.error("Error verifying OTP:", error);
    setOtpError(true);

  }
};


  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

     const scrollToSolutions = () => {
    if (solutionsRef.current) {
      solutionsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };



    useEffect(() => {
      if(content == 0) {
    const timer = setTimeout(() => {
      setUnlocked(true);
    }, 2000); 
    
    const finalTimer = setTimeout(() => {
      setLoading(false);
    }, 3000); 

    return () => {
      clearTimeout(timer);
      clearTimeout(finalTimer);
    };
  }
  else{
    setLoading(false);
  }
  }, []);
  if (loading) {
    return (
      <LoadingWrapper>
        <LoadingIcon>
       {unlocked ? <FaUnlock /> : <FaLock />}
        </LoadingIcon>
        <LoadingBar />
        <p>Decrypting...</p>
      </LoadingWrapper>
    );
  }
  return (
    <>
    {content === 0 ? (<><GlobalStyle />
      <Navbar>
      { isMobileMenuOpen ? <CloseMenu  onClick={toggleMobileMenu} /> : <BurgerMenu onClick={toggleMobileMenu} />}
        <Box sx={{ display: 'flex', alignItems: 'center', '@media screen and (max-width: 768px)': {
                   display: 'flex',
                   width: '100%',
                   alignItems: 'center',
                   justifyContent: 'center'
                  } }}>
          <Logo src={logo} alt="Hash Secure" />
          <CompanyName>HASH SECURE</CompanyName>
        </Box>
        <NavLinks>
          <NavLink href="#FeaturesTab">Features</NavLink>
          <NavLink href="#PricingTab">Pricing</NavLink>
          <NavLink href="#AboutUsTab">About Us</NavLink>
          <NavLink href="#ContactUsTab">Contact Us</NavLink>
          <NavLink href="#">Login</NavLink>
        </NavLinks>
        
       
          <MobileMenu isOpen={isMobileMenuOpen}>
             <MobileNavLink  onClick={toggleMobileMenu} href="#FeaturesTab">Features</MobileNavLink >
          <MobileNavLink  onClick={toggleMobileMenu} href="#PricingTab">Pricing</MobileNavLink >
          <MobileNavLink  onClick={toggleMobileMenu} href="#AboutUsTab">About Us</MobileNavLink >
          <MobileNavLink  onClick={toggleMobileMenu} href="#ContactUsTab">Contact Us</MobileNavLink >
          <MobileNavLink onClick={toggleMobileMenu}  href="#">Login</MobileNavLink >
          </MobileMenu>
    
      </Navbar>
      <MainSection>
        <ImageContainer>
          <MainImageImg  src={MainImage} alt="Main Image" />
        </ImageContainer>
        <TextContainer>
          <Heading>Revolutionize Security Testing</Heading>
          <SubText>All-in-one Tool for Developers &< br />
Bug Bounty Hunters</SubText>
          <ButtonE onClick={scrollToSolutions}>
            Explore More <FaArrowCircleDown />
          </ButtonE>
        </TextContainer>
      </MainSection>
      <FeaturesSection ref={solutionsRef} id="FeaturesTab">
            <FeaturesHeading>Our Features</FeaturesHeading>
            <FeaturesSubText>Discover the powerful features we offer to secure your digital presence.</FeaturesSubText>
            <FeaturesGrid>
              <FeatureCard>
                <FeatureIcon><MdEmail /></FeatureIcon>
                <FeatureText><b>Email Breach Scan</b></FeatureText>
                <FeatureText>Scan your email for breaches and know if your email has been compromised on any platform and when it occurred.</FeatureText>
              </FeatureCard>
              <FeatureCard>
                <FeatureIcon><FaNetworkWired /></FeatureIcon>
                <FeatureText><b>Open Port Scan</b></FeatureText>
                <FeatureText>Scan your site for open ports and get a detailed list of all the open ports for better security management.</FeatureText>
              </FeatureCard>
              <FeatureCard>
                <FeatureIcon><FaShieldAlt /></FeatureIcon>
                <FeatureText><b>Phishing URL Scan</b></FeatureText>
                <FeatureText>Check if a domain or URL is involved in phishing activities to prevent malicious attacks.</FeatureText></FeatureCard>
              <FeatureCard>
                <FeatureIcon><FaUnlock /></FeatureIcon>
                <FeatureText><b>Email Security Scan</b></FeatureText>
                <FeatureText>Verify if your domain's email records (SPF, DKIM, DMARC) are present and properly configured for enhanced email security.</FeatureText>
              </FeatureCard>
            </FeaturesGrid>
          </FeaturesSection>

          <JoinWaitlistSection>
        <JoinWaitlistHeading>Join the Waitlist</JoinWaitlistHeading>
        <JoinWaitlistSubheading>Sign up to be the first to know when we launch!</JoinWaitlistSubheading>
        {!isEmailSubmitted ? ( !progress ? (
          <JoinWaitlistForm>
            <Box sx={{width: '100% !important', display: 'flex', flexDirection:'row', justifyContent: 'space-between',
              '@media screen and (max-width: 768px)': {
                  flexDirection: 'column'
                
              },
            }}>
             <TextField
              label="Name"
              variant="outlined"
              fullWidth
              value={name}
              onChange={handleNameChange}
              error={nameError}
              helperText={nameError ? 'Please enter a name' : ''}
             sx={{width: '100%', marginRight: '20px', '@media screen and (max-width: 768px)': {
                   
              marginBottom: '10px',
              marginRight: '0',
              
            }, }}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={handleEmailChange}
              error={emailError}
              helperText={emailError ? 'Please enter a valid email' : ''}
              sx={{width: '100%',}}
            />
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEmailSubmit}
              style={{ marginTop: '20px', backgroundColor: '#2332B7' }}
            >
              Join Waitlist
            </Button>
          </JoinWaitlistForm> ) : ( <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>)
        ) : !isOtpVerified ? (
          <Box>
            <Typography variant="h6" gutterBottom>
              One time password to Join the waitlist (Sent on email)
            </Typography>
            <center>
            <OtpField>
              {otp.map((digit, index) => (
                <TextField
                  key={index}
                  variant="outlined"
                  value={digit}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                />
              ))}
            </OtpField>
            </center>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOtpSubmit}
              style={{ marginTop: '20px', backgroundColor: '#2332B7', fontWeight: 'bold' }}
            >
              Submit
            </Button>
            {otpError && (
              <Alert severity="error" style={{ marginTop: '20px' }}>
                OTP does not match, please try again.
              </Alert>
            )}
          </Box>
        ) : (
          <Typography variant="h6" gutterBottom>
            You have successfully joined the waitlist!
          </Typography>
        )}
      </JoinWaitlistSection>
      <PricingSection id="PricingTab">
            <PricingHeading>Pricing</PricingHeading>
            <PricingSubText>Our pricing is flexible and based on credits, so you only pay for what you use.</PricingSubText>
            <PricingGrid>
            <PricingCard>
                <CreditsIcon color='blue' />
                <PricingAmount>5 Credits - Free</PricingAmount>
                <PricingDescription>Free Plan</PricingDescription>
                <PricingButton>Try Now</PricingButton>
              </PricingCard>
              <PricingCard>
                <CreditsIcon color='blue'/>
                <PricingAmount>100 Credits - $10</PricingAmount>
                <PricingDescription>Basic Plan</PricingDescription>
                <PricingButton>Choose Plan</PricingButton>
              </PricingCard>
              <PricingCard>
                <CreditsIcon color='blue'/>
                <PricingAmount>500 Credits - $40</PricingAmount>
                <PricingDescription>Standard Plan</PricingDescription>
                <PricingButton>Choose Plan</PricingButton>
              </PricingCard>
              <PricingCard>
                <CreditsIcon color='blue' />
                <PricingAmount>1000 Credits - $65</PricingAmount>
                <PricingDescription>Premium Plan</PricingDescription>
                <PricingButton>Choose Plan</PricingButton>
              </PricingCard>
            </PricingGrid>
          </PricingSection>
      <AboutUsSection id="AboutUsTab">
        <AboutUsHeading>About Us</AboutUsHeading>
        
        <AboutUsMainContainer>
        <AboutUsContainer>
        <AboutUsText>
        Hash Secure was founded with the mission to provide top-notch automated cybersecurity solutions to businesses of all sizes. Our advanced tools are designed to scan websites, emails, and ports to identify vulnerabilities without the need for manual intervention. We are committed to delivering the best products and services to help your business succeed. Innovation, customer satisfaction, and continuous improvement are at the core of our values. Our goal is to stay ahead of the latest threats and ensure that our clients' data and systems are secure.
          <br /><br />
          Our solutions are built on expertise in various fields including network security, application security, and data protection. We offer automated tools that cater to your specific needs, providing tailored solutions that meet your unique requirements. From initial setup to ongoing support, our automated systems are here to help you every step of the way.
        </AboutUsText>
        </AboutUsContainer>
        <AboutUsImageContainer>
          <AboutUsImageComp src={AboutUsImage} alt="About Us"/>
        </AboutUsImageContainer>
        
        </AboutUsMainContainer>
      </AboutUsSection>
     <ContactUsSection id={'ContactUsTab'}>
    <ContactUsHeading>Contact Us</ContactUsHeading>
    <ContactUsSubheading>Any questions or remarks? Just write us a message!</ContactUsSubheading>
    <ContactUsMDiv>
      <ContactUsImgContainer>
        <ContactUsImg src={ContactusImage} alt='Contact Us'/>
      </ContactUsImgContainer>
    <ContactForm>
      <InputWrapper>
        <div>
          {/* <Label htmlFor="email">Email</Label> */}
          <TextField type="email" variant='outlined' key="Email" sx={{backgroundColor: 'white', width: '100%',  '@media screen and (max-width: 768px)': {
                   
                    marginBottom: '10px'
                  },}}  InputLabelProps={{ required: false }} label="Email" id="email" required />
        </div>
        <div>
          {/* <Label htmlFor="name">Name</Label> */}
          <TextField type="text" id="name" variant='outlined' key="Name" sx={{backgroundColor: 'white', width: '100%'}} InputLabelProps={{ required: false }} label="Name"  required />
        </div>
      </InputWrapper>
      <InputWrapper>
      <div>
      {/* <Label htmlFor="message">Message</Label> */}
      <TextField id="message" name="message" variant='outlined' key="Message" sx={{backgroundColor: 'white', width: '100%', marginBottom: '20px'}}   InputLabelProps={{ required: false }}  label="Message" multiline rows={10} maxRows={12} required />
      <Button variant="contained" sx={{width: '50%',  backgroundColor: '#2332B7', fontWeight: 'bold'}} type="submit">SUBMIT</Button>
      </div>
      </InputWrapper>
    </ContactForm>
   
    </ContactUsMDiv>
    <div style={{display: 'flex', width:'100%', justifyContent: 'center', flexDirection: 'column'}}>
    <p style={{fontSize: '20px'}}><b>Or</b></p>
    <p style={{fontSize: '18px'}}>Mail us at <a href={'mailto:contact@hash-secure.com'} style={{textDecoration: 'none', color: 'black', paddingLeft: '0px'}}> <b>contact@hash-secure.com</b></a></p>
    </div>
  </ContactUsSection>
       <Footer>
        <FooterDiv style={{display: 'flex', flexDirection:'row', justifyContent: 'space-between', width: '100%'}}>
       <div style={{ display: 'flex', flexDirection: 'column' }} >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center' }}>
          <FooterLogo src={logo} alt="Hash Secure" />
          <CompanyName style={{paddingLeft:'10px'}}>HASH SECURE</CompanyName>
        </div>
        <FooterNav>
                  <FooterNavLink href="#FeaturesTab">Features</FooterNavLink>
          <FooterNavLink href="#PricingTab">Pricing</FooterNavLink>
          <FooterNavLink href="#AboutUs">About Us</FooterNavLink>
          <FooterNavLink href="#ContactUsTab">Contact Us</FooterNavLink>
          <FooterNavLink href="#">Login</FooterNavLink>
        </FooterNav>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{paddingTop:'5px'}}><b>Hash Secure Pvt Ltd</b></span>
          <span style={{paddingTop:'5px'}}>G1, Bape Tower</span>
          <span style={{paddingTop:'5px'}}>Thane (W), Maharashtra,</span>
          <span style={{paddingTop:'5px'}}>India</span>
          <span style={{paddingTop:'10px', display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center'}}><MdEmail size={24} />  <a href={'mailto:contact@hash-secure.com'} style={{textDecoration: 'none', color: 'white', paddingLeft: '5px'}}> contact@hash-secure.com</a></span>
        </div>
        </FooterDiv>
        <Copyright>&copy; 2024 Hash Secure. All rights reserved.</Copyright>
      </Footer></>) : content === 1 ? (<><pre>{dummyPasswd}</pre></>) : content === 2 ? (<><pre>{DummyShadow}</pre></>)  :(   
       <Box sx={{display: 'flex' ,justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f5f5f5','@media screen and (max-width: 768px)': {
                   
          height: '100% !important'
            },}} >
      <Box sx={{display: 'flex', height: '100%',  flexDirection: 'column', alignItems: 'center'}}>
      <Typography sx={{fontWeight: 'bold', marginTop: '20px',fontSize:'60px','@media screen and (max-width: 768px)': {
                   
                  fontSize: '30px'
                     },}}>You are caught!</Typography>
      <Typography sx={{fontSize:'30px','@media screen and (max-width: 768px)': {
                   
                  fontSize: '20px'
                     },}}>There's no file inclusion but a joke!</Typography>
      <img src={caught} alt="not found" loading='lazy' width={400} height={400} />
      </Box>
    </Box>)}

    </>
  );
};

export default App;